
.def-save {
  order: 1;
}
@media (max-width: 800px) {
  .internal_line2,
  .city_number {
    .header {
      .title,
      .add-item {
        font-size: 14px !important;
      }
    }
  }
}
