
#settings {
  header {
    text {
      letter-spacing: 0.05em;
    }
  }

  // .header.vue-portal-target {
  //   position: sticky;
  //   top: 0;
  //   background-color: #fff;
  //   z-index: 10;
  // }
  // .scroll-menu-hor {
  //   position: sticky;
  //   top: 93px;
  //   background-color: #fff;
  //   z-index: 11;
  // }
}

.settings {
  background-color: white;
  .header.empl {
    padding: 0;
  }
  .settings__but-back {
    margin-bottom: 20px;
  }
  .scroll-menu-hor {
    .simplebar-track.simplebar-horizontal {
      display: none;
    }
  }
  .simplebar-content-wrapper {
    border-bottom: 1px solid #dee2e6;
    .nav-tabs {
      border-bottom: none;
      .nav-item {
        margin-bottom: 0px;
      }
      .nav-link {
        max-height: 38px;
      }
    }
  }
  .second-menu {
    flex-wrap: initial;
    &.right {
      margin-right: 50px;
    }
    .nav-item {
      white-space: nowrap;
    }
  }
  .header {
    display: flex;
    & > * {
      display: block;
      padding: 30px;
      width: fit-content;
    }
    .text {
      max-width: 50%;
      margin-top: 14px;
    }
  }
}
@media (max-width: 800px) {
  .header {
    .text {
      padding-bottom: 25px;
      padding-top: 26px;
    }
  }
}
@media (max-width: 800px) {
  .page {
    padding-top: 0px !important;
  }
}

.invisible_el {
  width: 0;
}

.second-menu {
  width: fit-content;
  .nav-scroll.right {
    position: sticky;
    right: 0;
    z-index: 1;
    background: linear-gradient(
      270deg,
      white -22.94%,
      rgba(255, 255, 255, 0) 330.33%
    );

    .nav-link {
      padding-left: 0;
      padding-right: 10px;
      max-height: 32px;
    }
  }
  .nav-scroll.left {
    position: sticky;
    left: 0;
    z-index: 1;
    @media (max-width: 800px) {
      left: 0;
    }
    background: linear-gradient(
      90deg,
      white -22.94%,
      rgba(255, 255, 255, 0) 330.33%
    );

    .nav-link {
      padding-left: 10px;
      padding-right: 0px;
      max-height: 32px;
    }
  }
}
