
.intLineObj {
  .fix-tabs {
    .nav-tabs {
      flex-wrap: nowrap;
      li {
        white-space: nowrap;
      }
    }
  }
  background-color: white;
  border-radius: 3px;
  .internal-call-list {
    padding-left: 15px;
    .input-group.search-input-group {
      max-width: 250px;
    }
  }
  #name_row {
    padding-left: 1em;
    h3 {
      display: flex;
    flex-flow: column wrap;
      font-weight: 700;
      font-size: 1.25rem;
      .active_comp {
        margin-top: 0.5em;
        font-size: initial;
        vertical-align: middle;
      }
    }
  }
}
