
@import "@/../static/SCSS/obit-colors.scss";

.footer.navbar-expand {
  // flex-flow: wrap;
  // justify-content: start;
  justify-content: center;
  align-items: baseline;
  align-content: center;
  display: flex;
  .landing-footer-bar {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    max-width: 1380px;
    padding: 0px 0 0 15px;
    .text-muted {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      margin: 10px 0 0 0;
    }
    @media (max-width: 801px) {
      // padding-right: 75px;
    }
    @media (max-width: 640px) {
      flex-flow: wrap;
      padding: 0;
    }
    a:hover {
      color: #fd6300; /* Цвет ссылки при наведении на нее курсора мыши */
    }
  }
  .item,
  .brand {
    margin-right: 30px;
    margin-top: 10px;

    a {
      font-size: 14px;
      line-height: 15.8px;
      font-weight: 400;
      color: $obit-footerlink;
      text-decoration: underline;
    }
    .active {
      color: #fd6300;
    }

    @media (max-width: 1280px) {
      margin-right: 15px;
    }
    @media (max-width: 801px) {
      margin-right: 30px;
    }
    @media (max-width: 500px) {
      margin-right: 20px;
    }
    @media (max-width: 404px) {
      margin-right: 13px;
    }
  }

  @media screen and (min-width: 1230px) {
    .navbar-brand.brand {
      // margin-left: auto;
    }
  }

  @media (max-width: 481px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
strong {
  line-height: 1.8rem;
}

@media (max-width: 640px) {
  .footer.navbar-expand {
    // .item {
    //   padding-bottom: 20px;
    // }
    .landing-footer-bar {
      .text-muted {
        width: auto;
        justify-content: end;
      }
    }
  }
}

@media (max-width: 480px) {
  .footer.navbar-expand {
    .landing-footer-bar {
      padding-bottom: 5px;
      width: auto;
      .text-muted {
      }
    }
  }
}
