
@import "@/../static/SCSS/obit-colors.scss";
.devices-input {
  h2.text {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
  .device-subform:not(:last-child) {
    border-bottom: 1px solid $obit-lightgray;
    margin-bottom: 1em;
  }
  label.devices-title {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .group-order-timeout {
    .order-timeout {
      font-size: 1rem;
      height: auto;
      border-right: 0px;
      padding-right: 0px;
    }
    .order-timeout:focus + .input-group-append .input-group-text {
      border-color: var(--primary) !important;
    }
    .input-group-append {
      .input-group-text {
        padding: 4px 7px 4px 0px;
        background-color: transparent;
      }
    }
  }
}
.mode-edit,
.mode-new {
  .devices-input {
    .group-order-timeout {
      width: 15%;
      @media (max-width: 650px) {
        width: 100%;
      }
      .order-timeout {
        max-width: 50%;
        @media (max-width: 650px) {
          max-width: 15%;
        }
      }
      .input-group-prepend {
        display: none;
      }
      .input-group-append {
        max-width: 50%;
      }
    }
  }
}
.mode-view {
  .devices-input .group-order-timeout {
    // width: 30%;
    // min-width: 160px;
    width: 50%;
    min-width: 160px;
    @media (max-width: 990px) {
      width: 100%;
    }
    .order-timeout {
      max-width: 40px;
      width: 30px;
    }
    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        background-color: transparent;
        font-size: 1rem;
        height: auto;
        border: 0px;
        padding: 0px;
        padding-right: 7px;
      }
    }
  }
}
