
/* .archive .form-inline.internal {
  justify-content: space-between !important;
} */

.modal-filter {
  .d-flex.col {
    padding-left: 0;
    &.additional-filter {
      padding-left: 0;
      @media screen and (min-width: 450px) {
        .form-group {
          & > [role="group"] {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            .obit-datepicker {
              &:not(:first-child) {
                margin-left: 1em;
              }
              .legend {
                margin-right: 1em;
              }
            }
          }
        }
      }
      @media screen and (max-width: 449px) {
        .form-group {
          & > [role="group"] {
            .obit-datepicker {
              margin-bottom: 0.5em;
              .legend {
                min-width: 2em;
              }
            }
          }
        }
      }
    }
  }
}

.archive.call {
  .btn-height {
    height: 37px;
  }
  .form-inline.external {
    justify-content: initial;
  }
  .additional-filter {
    display: inline-flex;
    div.bv-no-focus-ring {
      display: inline-flex;
    }
    & .btn {
      margin-right: 0;
    }

		.form-group > div {
			display: flex;
			align-items: center;
		}
  }
  .form-inline {
    .col.search {
      flex-grow: 1;
      max-width: 100%;
      .search-input-group {
        width: 100%;
        max-width: 210px;
      }
    }
    &.external {
      justify-content: initial;
    }
    &.line {
      justify-content: initial;
    }
  }
  .btn-filter-height {
    height: 37px;
    border-radius: 6px 6px 6px 6px;
    &.btn-group-toggle {
      label {
        padding-left: 15px;
        padding-right: 15px;
        color: #3e3e3e;
      }
    }
    &.search-input.focus-input {
      border-radius: 6px 0px 0px 6px;
    }
    &.search-input::placeholder {
      color: #3e3e3e;
    }
  }
  .focus {
    border-color: var(--primary) !important;
  }
  // .focus-input {
  //   max-width: 157px;
  // }
  .clear-input {
    padding-bottom: 4px;
  }
  .btn-right {
    width: 115px;
    white-space: nowrap;
  }
  .btn-filter-reset {
    border: none;
  }
  .btn-filter-text {
    font-size: 11px;
  }
  .search-input-group {
    min-width: 150px;
    @media (min-width: 1000px) {
      min-width: 286px;
    }
  }
}
