
@import "../../../../static/SCSS/obit-colors";
.employee-status {
  &.status-active {
    color: $obit-success;
  }
  &.status-deleted {
    color: $obit-danger;
  }
  &.status-not-approved {
    color: $obit-warning;
  }
  &.status-not-active {
    color: $obit-danger;
  }
}
