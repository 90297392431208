
@import "@/../static/SCSS/obit-colors.scss";
.node.city_number-node {
  .name {
    font-size: 2em;
    margin-bottom: 0;

    font-weight: 300;
    .output-socket {
      float: right;
      top: -11px;
      position: relative;
      left: 16px;
    }
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -24px !important;
    }
  }
  .output-socket.output {
    display: inline-block;
    position: relative;
    left: calc(100% - 18px);
    &.limit {
      background-color: $obit-danger !important;
    }
  }
}
