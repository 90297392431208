
.object-form-pbx_group {
  max-width: 930px;
}
.password-data-form {
  max-width: 930px;
}
.pbx-group-list {
  h3.title {
    padding-top: 15px;
    margin-left: 30px;
  }
  .group_members {
    margin-top: 15px;
  }
}
.pbx-members-add {
  .table-responsive.objects-table.objects-internal_line2 {
    max-height: calc(100vh - 270px);
  }
}
