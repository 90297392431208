
div.dashcard {
  border-radius: 6px;
  background-image: linear-gradient(#f4f4f4, #f2f9fc);
  margin: 30px;
  margin-right: 0px;
  margin-top: 30px;
  padding: 20px;
  min-height: 207px;
  .card-line {
    margin-top: 8px;
  }
  .lines-stats {
    font-size: xxx-large;
  }
  .card-block {
    margin-top: 28px;
  }
  .active {
    color: #81de48;
  }
  .inactive {
    color: #fd6300;
  }
  .stuff:not(.no-link) {
    color: #54bae7;
  }
  .sorcery {
    font-size: x-large;
    margin-left: 20px;
  }
}
div.top-card {
  max-width: 359px;
  min-height: 248px;
}
.small-card {
  min-height: 195px !important;
  margin-top: 30px !important;
  margin-bottom: 15px !important;
  max-width: 360px;
  max-height: 160px;
}
.small-block {
  margin-top: 30px !important;
}
.small-stats {
  font-size: xx-large !important;
}
.bad {
  background-image: linear-gradient(#f4f4f4, #ffd4d4) !important;
}
.topic {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px !important;
  padding: 20px;
  padding-bottom: 0px;
}
.margform {
  margin-left: 15px;
}

@media (max-width: 1600px) {
  .graphic {
    width: 750px;
    position: relative;
    // height: 307px;
    // overflow-x: scroll;
  }
  .topic {
    margin-left: 10px !important;
  }
  div.dashcard {
    margin-left: 15px;
    padding: 15px;
    min-width: 240px;
    .small-card {
      min-width: 270px;
    }
  }
  .margform {
    margin-left: 0px;
  }
}
.obit-icon {
  height: 1.2em;
}
.update-date {
  color: #bfbfbf;
  text-transform: none !important;
  @media (max-width: 480px) {
    display: none;
  }
}

.refresh-button {
  position: absolute;
  top: -50%;
}
.first-sm {
  margin-left: 15px !important;
}

@media (max-width: 620px) {
  .refresh-button {
    top: -6%;
    right: -5%;
  }
}

@media (min-width: 1199px) {
  .first-sm {
    margin-left: 30px !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 600px) and (max-width: 858px) {
  .refresh-button {
    top: -5%;
    right: -5%;
  }
}

@media (max-width: 800px) {
  .top-card {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-right: 30px !important;
  }
  .margform {
    margin-left: 20px;
  }
}

@media (min-width: 799px) and (max-width: 1200px) {
  .small-card {
    margin-left: 45px !important;
  }
}

@media (min-width: 1280px) {
  .simplebar-content-wrapper {
    overflow: hidden;
  }
  .first-tc {
    margin-left: 30px !important;
  }
}
.teledash-test-style {
  max-width: 1450px;
}
