
@import "../../../static/SCSS/obit-colors.scss";
.manager-menu {
  padding: 0.5em 2em;
  background-color: $obit-darker;
  padding: 30px;
  margin-top: auto;
  display: block;
  .side-menu & {
    display: list-item;
  }
  h5 {
    line-height: 14.4px;
    font-size: 12px;
    color: $obit-dark;
  }
  h4 {
    font-size: 14px;
    line-height: 16.8px;
    float: right;
    padding: 0.25em;
    min-height: 3em;
    width: calc(100% - 3.5em);
  }
  .btn {
    padding: 1em 2em;
    font-size: 9px;
    line-height: 10.8px;
    max-width: 267px;
    body.vertical & {
      font-size: 14px;
      line-height: 16.8px;
      padding: 12px 24px;
    }
    &.btn-manager {
      &:focus {
        background-color: var(--primary) !important;
        border-color: var(--primary) !important;
      }
      &:hover {
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
      }
    }
  }
  .manager-modal-footer {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}
