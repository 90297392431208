
#periodInput {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;

  .obit-datepicker:not(:last-child) {
    margin-right: 17px;
  }

  .legend {
    margin-right: 7px;
  }
}
