
.obit-datepicker {
  flex-wrap: nowrap;
  &.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .legend {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 0.8em;
  }
  &:hover input,
  &:hover button.btn {
    border-color: var(--primary) !important;
  }
  &.focus {
    button.btn {
      border-color: var(--primary) !important;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
  }
  .cal {
    width: 100%;
    height: 100%;
  }
  .small {
    height: 0;
    border-top: none !important;
  }
  .rounded-circle {
    box-shadow: none !important;
  }
  .font-weight-bold {
    font-weight: 400 !important;
  }
  .b-calendar-grid {
    border: none;
    width: 250px;
    div[aria-label$="(Today)"] {
      .btn {
        color: var(--primary) !important;
      }
    }
    div[aria-selected="true"] {
      .btn {
        color: var(--warning) !important;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .border-bottom {
    border-bottom: none !important;
  }
  .noboroder button.btn-outline-obit {
    border: 1px solid #ced4da;
    border-left: none;
    fill: var(--primary);
    padding: 0.375rem 0.75rem;
    &:active {
      border-color: #ced4da;
    }
    &:hover {
      color: var(--primary) !important;
      fill: var(--primary);
    }
  }
  .obit-icon {
    height: 1.2em;
  }
  .input-group {
    flex-wrap: nowrap;
  }
  input {
    border-right: none;
    min-width: 100px !important;
    max-width: 100px;
    padding-right: 0;
  }

  max-width: 160px;
  min-width: 140px;
}
