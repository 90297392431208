
@import "../static/SCSS/obit.scss";
@import "~bootstrap-vue/src/index.scss";
@import "~simplebar/dist/simplebar.min.css";
// @import "../static/SCSS/style.scss";

body {
  overflow: hidden;
}

.messages {
  width: 30vw;
  position: absolute;
  z-index: 3000;
  right: 1%;
  top: 1%;
  height: fit-content;
}

.hidden {
  display: none !important;
}

#app {
  height: auto;
}

svg.inline {
  vertical-align: bottom;
  display: inline-block;
}

.loading {
  position: absolute;
  color: whitesmoke;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.loading > * {
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
}
