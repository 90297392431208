
@import "@/../static/SCSS/obit-colors.scss";
.select-number {
  h3 {
    padding: 30px 30px;
    padding-bottom: 0px;
    text-transform: uppercase;
  }
  & > h2 {
    padding: 5px 30px;
  }
  form {
    padding-left: 30px;
  }
  .numbers-aval-list {
    padding: 15px 30px;
    th {
      color: $obit-muted;
    }
  }
  .bottom {
    padding: 30px;
    padding-top: 5px;
    button {
      margin-right: 15px;
    }
  }
}
