
.calls-filter {
  max-width: 930px;
  .form-row {
    margin-right: 3em;
    &.filter,
    &.export {
      .btn {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        padding: 10px;
        vertical-align: middle;
        margin-left: 2em;
      }
      &.export .btn-outline-success {
        color: #40cc5b;
        border-color: #40cc5b;
      }
    }
    .btn.btn-outline {
      &.active {
        background-color: var(--primary);
        color: white;
        border: none;
      }
      border: 1px solid lightgrey;
    }
  }
}
