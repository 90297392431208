@import "./obit-colors";
@import "../fonts/lato/lato";
@import "../SCSS/base/drop-down-dots";

$text-color: $obit-black;
$body-color: $obit-black;
$text-muted: $obit-darkgray;
$grid-breakpoints: (
  xs: 0,
  sm: 456px,
  md: 789px,
  lg: 999px,
  xl: 1234px
);
$basic-link-color: $obit-primary;
$theme-colors: (
  "primary": $obit-primary,
  "danger": $obit-danger,
  "success": $obit-success,
  "warning": $obit-warning,
  "obit": $obit-primary,
  "obitless": $obit-danger,
  "export": $obit-export,
  "sunrise": $obit-sunrise
);

$font-family-sans-serif: "Lato", Arial, sans-serif;
@import "~bootstrap/scss/bootstrap";

@mixin define_text($size, $line, $color: $obit-black, $weight: 100) {
  line-height: $line;
  font-size: $size;
  color: $color;
  fill: $color;
  font-weight: $weight;
}

body {
  @include define_text(14px, 16.8px, $obit-black, 400);

  // @media screen and (max-width: 1000px) {
  //     @include define_text(16px, 19.2px, $obit-black, 400);
  // }
  p,
  span,
  div {
    // @include define_text(22px, 25.78px, black, 500);
    .muted-text {
      @include define_text(12px, 14.06px, $obit-gray, 400);
    }
  }

  h1,
  .header {
    @include define_text(16px, 19.2px, $obit-black, 700);
    text-transform: uppercase;
  }

  h2 {
    @include define_text(14px, 16.8px, $obit-black, 600);
  }

  legend.col-form-label,
  .col-form-label,
  .custom-control-label {
    @include define_text(14px, 16.8px, $obit-black, 400);
    font-style: normal;
  }

  .text-muted {
    @include define_text(14px, 16.8px, $obit-gray, 400);
  }
}

.btn-obit {
  @include button-variant($obit-primary, $obit-primary, $obit-warning, $obit-warning, $obit-primary, $obit-primary);
  color: white;
  text-transform: uppercase;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 700;

  &:disabled,
  &.disabled {
    background-color: $obit-darkgray;
    color: white;
    border: none;
  }

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: white !important;
    background-color: $obit-warning !important;
    border-color: $obit-warning !important;
  }

  &:hover,
  &:active:hover {
    color: white !important;
  }
}

.btn-obit-orange {
  @include button-variant($obit-warning, $obit-warning, $obit-danger, $obit-danger, $obit-danger);
  color: white;
  text-transform: uppercase;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 700;

  &:disabled,
  &.disabled {
    background-color: $obit-darkgray;
    color: white;
    border: none;
  }

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: white !important;
    background-color: $obit-danger !important;
    border-color: $obit-danger !important;
  }

  &:hover,
  &:active:hover {
    color: white !important;
  }
}

.btn.btn-outline-primary {
  &.active {
    color: white !important;

    &.focus {
      color: white !important;
      background-color: $obit-primary !important;
    }
  }

  [role="radiogroup"] & {
    border-color: $obit-gray;

    &:hover {
      border-color: $obit-gray !important;
    }
  }
}

.btn-outline-obit {
  @include button-outline-variant($obit-primary, $obit-warning, white, $obit-warning);
  text-transform: uppercase;
  padding: 0.5em 2em;

  padding: 12px 24px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 700;

  &:disabled,
  &.disabled {
    border-color: $obit-darkgray;
    color: $obit-gray;
  }
  &.active {
    background-color: $obit-primary !important;
    border-color: $obit-primary !important;
    color: white !important;
    &:hover {
      background-color: $obit-warning !important;
      border-color: $obit-warning !important;
      color: white !important;
    }
    &:active,
    &:focus,
    &.focus {
      color: white !important;
    }
  }

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: $obit-primary !important;
  }

  &:hover,
  &:active:hover {
    color: $obit-warning !important;
  }

  .show > &.dropdown-toggle {
    color: $obit-primary;
  }
}

.btn-outline-obitless {
  @include button-outline-variant($obit-primary, $obit-warning, transparent, $obit-warning);
  text-transform: uppercase;
  border: none;
  padding: 0.5em 2em;

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: $obit-primary !important;
  }

  &:hover,
  &:active:hover {
    color: $obit-warning !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $obit-primary;
  }

  .show > &.dropdown-toggle {
    color: $obit-primary;
  }
}

.btn-outline-export {
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.05em;

  &:disabled,
  &.disabled {
    border-color: $obit-darkgray;
    color: $obit-gray;
    background-color: transparent;
  }

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: $obit-export !important;
    border-color: $obit-export !important;
    background-color: transparent !important;
  }

  &:hover,
  &:active:hover {
    color: $obit-warning !important;
    border-color: $obit-warning !important;
    background: transparent;
  }
}

.btn-outline-primary {
  &:disabled,
  &.disabled {
    border-color: $obit-darkgray;
    color: $obit-gray;
    background-color: transparent;
  }

  &:focus,
  &.focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    color: $obit-primary !important;
    border-color: $obit-primary !important;
    background-color: transparent !important;
  }

  &:hover,
  &:active:hover {
    color: $obit-warning !important;
    border-color: $obit-warning !important;
    background: transparent;

    .obit-icon,
    svg {
      color: $obit-warning !important;
    }
  }
}

.text-obit {
  color: $obit-primary;
}

.obit-icon {
  display: inline-block;
  height: 1.4em;
  width: 1.4em;
  line-height: 1.4em;
  text-align: center;
  vertical-align: middle;
  outline: none;
  svg {
    display: inline;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color},
  .btn-outline-#{$color},
  .text-#{$color} {
    .obit-icon {
      fill: inherit;
    }
  }
}

.objects-list,
.object-form {
  background-color: white;
  border-radius: 4px;

  .row.header {
    padding: 30px;
    margin: 0;

    .title {
      font-size: 16px;
      text-transform: uppercase;
    }

    .add-item {
      float: right;
      padding: 0;
    }
  }

  .items,
  .item {
    padding: 15px 15px 0 15px;
  }
}

.objects-list {
  .row.header {
    border-bottom: 1px solid $obit-darkgray;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  border: none;
  opacity: initial;
}

.nav-tabs {
  .nav-link {
    //  font-size: 14px;
    //   line-height: 16.8px;
    color: $obit-black;
    font-weight: 600;
    padding: 10px 30px;

    &:hover,
    &:focus {
      border-color: transparent;
      color: $obit-warning;
    }

    &.active,
    .nav-item & {
      color: $obit-warning;
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid $obit-warning;
    }
  }
}

.custom-select:disabled {
  color: black;
  background-color: white;
  border: 0;
  background: none;
  padding-left: 0;
}

div.multiselect.select2.multiselect--disabled {
  // margin-left: 6px;
  .multiselect__tags {
    border: 0px;
    padding-left: 0px;
    color: black;
    .multiselect__tag {
      padding-right: 10px;
      .multiselect__tag-icon {
        display: none;
      }
    }
  }
  .multiselect__select {
    display: none;
  }
}

.multiselect--disabled .multiselect__single {
  padding-left: 0;
}
.form-control.border-0 {
  padding-left: 0;
}
.week-input.disabled {
  padding-left: 0;
}
.input-group-prepend button.btn.disabled {
  padding-left: 0;
}

.custom-control-label {
  position: relative;
  /* margin-bottom: 0; */
  vertical-align: middle;
  line-height: 1.5em !important;
  padding-left: 0.5em;

  &:hover {
    &::before,
    &::after {
      border-color: $obit-primary;
      box-shadow: $obit-primary 1px;
    }
  }

  &::before,
  &::after {
    top: 0;
    width: 1.5em;
    height: 1.5em;
  }
}

.obit-paginator.pagination {
  .page-item,
  .page-item {
    &.disabled {
      display: none;
    }

    span,
    button {
      height: 2.5em;
      width: 2.5em;
      border: none !important;
      border-radius: 50% !important;
      margin: 0;
      padding: 0;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        color: $obit-warning;
      }
    }

    &.active {
      span,
      button {
        background-color: $obit-warning !important;
        color: white;
      }
    }
  }
}

table {
  th:focus {
    outline: none !important;
  }
}

.table-responsive.objects-table,
.form-row.default_filter {
  max-width: 930px;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.p-vert {
  padding-left: 20px;
  padding-right: 20px;
}

.p-obit-mitddle {
  padding: 7px 20px;
}

.text-button {
  font-weight: 700;
  font-size: 11px;
  line-height: 13.2px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $obit-primary;
  border-color: $obit-lightgray;
  background-color: white;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2354BAE7' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-control-label::before {
  background-color: #fff;
  border: $obit-lightgray solid 1px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2354BAE7'/%3e%3c/svg%3e");
}

.custom-control.custom-checkbox,
.custom-control.custom-radio {
  margin-bottom: 10px;
}

// menu primary

ul.dropdown-menu {
  li {
    button.text-primary {
      &:hover {
        background-color: inherit;
        color: $obit-warning !important;
      }
    }
  }
}

.form-control {
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: var(--primary) !important;
  }
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

@media screen and (max-width: 800px) {
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.modal-dialog {
  max-width: 650px;

  .modal-footer {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  .modal-header {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
  }

  .modal-footer,
  .modal-header,
  .modal-body {
    padding: 15px 30px;
  }
}

// номера
.object-form-internal_line2 {
  fieldset.number {
    input {
      max-width: calc(5em + 7px);
    }
  }
}
.badge-primary {
  color: var(--white);
  background-color: var(--primary);
}

.modal-title {
  text-transform: uppercase;
}

.material-icons-outlined {
  font-size: 1em;
}

.margin-left-auto {
  margin-left: auto;
}

// fix modals on phone
body.vertical > div > div[role="dialog"] {
  padding-left: 0 !important;
  .modal-dialog {
    margin: 0;
    .modal-content {
      width: 100vw;
    }
    .modal-body {
      padding: 15px;
    }
  }
}
#rete .node h2 svg.obit-icon {
  font-size: 2em;
}
