
@import "@/../static/SCSS/obit-colors.scss";
$breakpoint: 760px;

.active-node {
  z-index: 99999;
}

.routing-page {
  $zindex: 10000;
  .no-options {
    min-height: 3em;
  }
  .foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(lighten(gray, 20%), 0.3);
    backdrop-filter: blur(5px);
    z-index: $zindex + 1;
    .spinner {
      width: 10rem;
      height: 10rem;
      position: absolute;
      top: calc(50% - 5rem);
      left: calc(50% - 5rem);
      .sp {
        width: 100%;
        height: 100%;
      }
    }
    // .logo {
    //   background-image: url("@assets/obit_logo.svg");
    //   width: 10rem;
    //   height: 10rem;
    // }
  }
  .help {
    display: none;
  }
  .zoom-panel {
    position: absolute;
    bottom: 1em;
    left: 1em;
    z-index: $zindex;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    .custom_zoom {
      max-width: 4em;
    }
  }
  .editor-controls {
    .btn-group.controls {
      display: flex;
      flex-flow: row nowrap;
      justify-content: start;
      align-self: flex-start;
      //width: 100%;

      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(5px);
      border-radius: 5px;
      z-index: $zindex;
      & > * {
        z-index: $zindex;
      }
      button.btn {
        flex: inherit;
      }
      .multiselect__input {
        display: inline;
        //max-width: 15em;
      }
    }

    .custom_zoom,
    .search-bar {
      max-width: 15em;
      display: inline-block;
      padding-top: 4px;
      border-radius: 0;
    }
    .search-bar {
      width: 15em;
    }

    height: fit-content;
    position: absolute;
    width: auto;
    left: 1em;
    top: 1em;

    &.right {
      left: auto;
      right: 1em;
    }

    @media screen and (max-height: $breakpoint) {
      &.openned {
        left: 6em;
      }
    }
    z-index: $zindex;
  }
  .tag-item {
    $margin-size: 0.25em;
    .close-icon {
      display: none;
    }
    &.text {
      background-color: transparent;
      color: black;
    }
    &:hover {
      background-color: $obit-danger;
      color: white;
      .text-danger {
        color: white !important;
      }
      .close-icon {
        display: inline-block;
        margin-left: 0.5em;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    margin-left: $margin-size;
    margin-right: $margin-size;

    padding-right: $margin-size;
    padding-left: $margin-size;
    font-size: 1em;
  }
  .sidebar {
    background-color: #f8f9fa;
    border: 1px solid gray;
    top: 0px;
    margin-left: calc(100% - 300px);
    width: 300px;
    position: absolute;
    border: 1px solid var(--light);
    .list-group.list-group-flush {
      max-height: calc(100vh - 310px);
      overflow: auto;
    }
  }
  .items-paletre {
    position: absolute;
    left: 1em;
    top: 6em;
    @media screen and (max-height: $breakpoint) {
      top: 1em;
    }
    z-index: 10000;
    display: flex;
    flex-direction: row;

    .btn-group {
      backdrop-filter: blur(3px);
      box-shadow: 0px 28px 64px -15px #54bae7;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-self: baseline;
      align-items: stretch;
      border-radius: 5px;
      overflow: auto;
      max-height: calc(100vh - 145px);
      background: rgba(255, 255, 255, 0.7);
      z-index: 10000;
      &:first-child {
        max-height: calc(100vh - 290px);
        flex-wrap: wrap;
        @media screen and (max-height: $breakpoint) {
          width: var(--hover-size);
          overflow: hidden;
        }
        button {
          flex-basis: inherit 4em;
        }
      }
      &:not(:first-child) {
        margin-left: 1em;
        box-shadow: 0px 28px 64px -35px #54bae7;
      }
      &.options {
        button.btn {
          text-align: left;
        }
      }
      button {
        max-width: 30em;
        .badge {
          // height: 0.5em;
          position: absolute;
          top: 10%;
          right: 10%;
          font-size: 0.5em;
        }
        .badge.unknown {
          top: inherit;
          bottom: 10%;
        }
      }
    }
  }
  .palitre {
    .sub-items {
      max-height: 30em;
      overflow: auto;
      min-height: 2em;
    }
  }
  &.add_item {
    .editor-controls,
    .items-paletre,
    .sidebar {
      display: none;
    }
    #rete {
      background-color: lighten($obit-success, 40%);
      cursor: copy;
    }
    .help {
      display: block;
      max-width: 50vw;
      position: absolute;
      top: 1.5em;
      left: 2em;
      z-index: 10000;
      backdrop-filter: blur(2px);
      padding: 0.5em;
      background-color: lighten(rgba($obit-success, 0.4), 20%);
      border-radius: 5px;
      // box-shadow: 0px 15px 10px -10px lighten($obit-success, 20%);
    }
  }
  #rete {
    // min-height: 600px;
    height: calc(100% - 40px);
    .card {
      min-width: 320px;
      width: 100%;
    }
    .connection .main-path {
      fill: none;
      stroke-width: 5px;
      stroke: var(--primary);
    }
    .connection.input-start.output-start {
      display: none;
    }
    .socket {
      border: 3px solid white;
      border-radius: 50%;
      background-color: var(--orange);
      box-shadow: 0 0 5px var(--primary);
      &:hover {
        box-shadow: 0 0 10px var(--primary);
      }
      &.input-socket {
        width: 0;
        height: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: unset;
        left: 0;
        border-top: 12px solid transparent;
        border-left: 24px solid var(--orange);
        border-bottom: 12px solid transparent;
      }
      &.output-socket.output.start,
      &.input-socket.input.start {
        display: none;
      }
    }
  }
}
