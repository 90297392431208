
@import "@/../static/SCSS/obit-colors.scss";

.call-player {
  border-radius: 100px;
  background: #54bae7;
  width: 100%;
  height: 100%;
  & > .btn,
  & > select,
  & > btn-group {
    height: 48px !important;
  }
  .break {
    display: none;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    border-radius: 20px;
    .break {
      flex-basis: 100%;
      height: 0;
      display: inline-flex;
    }
    .small-btn.volume-btn {
      margin-left: auto;
    }
  }

  .end {
    .dropdown-toggle {
      border-radius: 20px;
      &[aria-expanded="true"] {
        border-radius: 0px 20px 0px 0px;
      }
    }
  }
  .small-btn {
    max-width: 2em;
    margin: 0;
    padding: 0 auto;
  }
  .speed {
    background: $obit-primary;
    color: white;
    border: none;
    width: 4em;
    border-radius: 40px;
    height: 100%;
    padding-left: 0.4em;
  }
  button.btn,
  label.btn {
    color: white;
    border: none;
    &:hover,
    &:focus {
      color: white !important;
      border: none;
      background-color: $obit-primary;
      .obit-icon,
      svg {
        color: white !important;
      }
    }
    &.call-timer {
      border-left: none;
      border-right: none;
      color: #fff;
      white-space: nowrap;
      .call-input {
        border: none !important;
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #54bae7 !important;
        border-bottom: 1px solid #54bae7 !important;
        color: #fff;
        margin-top: -5px;
      }
      span {
        margin-top: -10px;
        display: block;
      }
      &:hover {
        border: none;
        background: #54bae7;
      }
    }
  }
  .volume-slider {
    background: $obit-primary;
    border-color: $obit-primary;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 10px;
    height: 3em;
    input {
      padding: 0 20px;
      margin: 0;
    }
  }
}
.call-play {
  padding: 0 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  color: #fff;
}
.call-play:hover {
  border: none;
  background: transparent;
}
.call-stop {
  border-left: none;
  border-right: none;
  color: #fff;
}
.call-stop:hover {
  border: none;
  background: #54bae7;
}

.call-volume {
  padding: 0 0 0 20px;
  border-left: none;
  color: #fff;
}
.call-volume:hover {
  border: none;
  background: #54bae7;
  color: #54bae7;
}
.call-input:focus {
  border: 2px solid #fff;
}
.call-input:hover {
  border: none;
  background: #54bae7;
}
input[type="range"]::-webkit-slider-thumb {
  background: #fff;
}
