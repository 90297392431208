
@import "@/../static/SCSS/obit-colors.scss";
.btn-group.ivr-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 161px;
  border-radius: 5px;
  border: 1px solid var(--primary);
  label.btn {
    width: 50px;
    border-radius: 0;
    border: none;
    margin: 0;
    &.disabled {
      background-color: $obit-success;
      color: white;
    }
  }
}
.card.ivr-node {
  padding-bottom: 10px;
  max-width: 250px;
  .name {
    font-size: 2em;
    line-height: 0.9em;
    max-width: calc(100% - 20px);
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;
    color: white;
    .settings {
      width: fit-content;
      display: inline;
      background-color: transparent;
      & > button.btn {
        color: white;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .output-line {
    margin-right: -4px;
    .out-ivr {
      border: 2px solid var(--primary);
      border-radius: 50px;
      max-width: 98%;
      width: fit-content;
      height: 32px;
      margin-bottom: 5px;
      float: right;
      .name {
        display: block;
        font-size: 24px;
        line-height: 1.2em;
        padding-left: 0.8em;
        padding-right: 30px;
        width: max-content;
        max-width: fit-content;
      }
      .output-socket.output {
        position: relative;
        left: calc(100% - 31px);
        top: -32px;
        &.timeout {
          background-color: $obit-danger !important;
        }
      }
    }
  }
  h2.name.file {
    margin: 0;
    padding-bottom: 0;
  }
}
