
.log-cell {
  padding: unset;
  .svg-inline--fa {
    vertical-align: middle;
  }
}
.field_log {
  width: 50px;
}
.log-cell-modal {
  .btn-outline-obit {
    display: none;
  }
}
