$obit-black: #3E3E3E;
$obit-primary: #54BAE7;
$obit-warning: #FD6300;
$obit-success: #81DE48;
$obit-danger: #EC6969;
$obit-gray: #EBEDEE;
$obit-darker: #E6E7E8;
$obit-darkgray: #BFBFBF;
$obit-dark: #9E9E9E;
$obit-lightgray: #ededed;
$obit-muted: #b1b1b1;
$obit-export: #40CC5B;
$obit-active: #a4a4a4;
$obit-sunrise: #ffc107;

$obit-footerlink: #A9A9A9;