
.media-cell {
  display: flex;
  align-items: baseline;
  .spinner-border {
    vertical-align: middle;
  }
  .media-btn {
    padding-left: 0;
  }

  .length {
    margin-left: 1em;
    padding-left: 20px;
  }
  .play-text {
    // padding-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    width: 190px;
  }
  .text-muted {
    margin: 0;
    padding: 0;
  }
}
