
.play-cell {
  padding: unset;
  .has_record {
    color: red;
  }
  .svg-inline--fa {
    vertical-align: bottom;
  }
}
td.field_record,
th.field_record {
  width: 50px;
}
