
.save-btn {
  order: 5;
}
.delete-btn {
  order: 4;
}
@media (max-width: 640px) {
  .editing-save {
    padding: 10px 18px;
  }
  .editing-cansel {
    padding: 10px 20px;
  }
  .editing-trash {
    padding: 0;
  }
}
