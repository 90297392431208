
#audio_player {
  display: none;
}
.file-subtext {
  display: block;
}
.media-block {
  padding: 22px;
  align-items: center;
  justify-content: space-between;
  > .bv-no-focus-ring {
    width: 50%;
  }
}
.media-cell {
  word-break: break-all;
}
.file-media-btn {
  padding-left: 0;
}
.file-btn {
  padding: 10px 30px;
}
.btn-select {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}
.file-name {
  padding-left: 10px;
}
