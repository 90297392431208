
@import "../../../static/SCSS/obit-colors.scss";

.sub-with-items.active {
  border: 0;
  border-left: $obit-warning 0.25em solid;
}
$gray: $obit-active;
.menu-item {
  color: $gray;
  font-size: 12px;
  line-height: 14.4px;
  width: 100%;
  font-weight: 700;
  @media screen and (max-width: 800px) {
    width: fit-content;
  }
  &.active {
    a.nav-link {
      color: $gray;
    }
  }
  a.nav-link {
    color: $obit-black;
    text-transform: uppercase;
    padding: 15px 30px;
    font-family: "latoheavy", Arial, sans-serif;
    &:hover {
      color: $obit-warning;
    }
    @media screen and (max-width: 800px) {
      width: max-content;
      padding-bottom: 22px;
      &.active {
        border: 0;
        border-bottom: $obit-warning 0.25em solid;
        color: $gray;
        fill: $gray;
      }
    }
    @media screen and (min-width: 801px) {
      padding-left: var(--padding-left);

      &.active {
        border: 0;
        border-left: $obit-warning 0.25em solid;
        color: $gray;
        fill: $gray;
        padding-left: 26px;
      }
    }
  }
}
.side-menu-icon {
  margin-left: 5;
}
