.dropdown-actions {
    float: right;
    button {
      padding-right: 2px;
      padding-left: 2px;
      color: #54BAE7
    }
    ul {
      padding: 20px 0px;
      margin-top: 10px;
      margin-right: 7px;
      a {
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        float: right;
        padding-top: 0;
        padding-bottom: 0;
        color: #54BAE7
      }
    }
  }
  .right {
    float: right;
  }