
.shortText {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* -webkit-line-clamp: 5; */
  max-width: 330px;
}
.big_tooltip {
  .tooltip-inner {
    max-width: 348px !important;
    width: 100% !important;
  }
}
