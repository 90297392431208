
.object-form.empl {
#name_row {
  margin-bottom: 1rem;
}
#name * {
  display: inline;
}
.empl.header {
  .back {
    padding-top: 0px;
    padding-left: 0px;
  }
  h1.header {
    padding-bottom: 0px;

    padding-top: 0px;
    padding-left: 0px;
  }
}
.empl {
  .row.back,
  .row.header {
    padding: 15px 10px;
    margin: 0;
  }
  .object {
    margin: 15px 30px;
  }
}
.btn-success {
  order: 2;
}
.btn-trash {
  order: -2;
}
}
