
.text-input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.text-block {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  color: #adadad;
}
