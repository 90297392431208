@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latohairline.woff2') format('woff2'), url('../static/fonts/lato/latohairline.woff') format('woff'), url('../static/fonts/lato/latohairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latohairlineitalic.woff2') format('woff2'), url('../static/fonts/lato/latohairlineitalic.woff') format('woff'), url('../static/fonts/lato/latohairlineitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latothin.woff2') format('woff2'), url('../static/fonts/lato/latothin.woff') format('woff'), url('../static/fonts/lato/latothin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latothinitalic.woff2') format('woff2'), url('../static/fonts/lato/latothinitalic.woff') format('woff'), url('../static/fonts/lato/latothinitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latolight.woff2') format('woff2'), url('../static/fonts/lato/latolight.woff') format('woff'), url('../static/fonts/lato/latolight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latolightitalic.woff2') format('woff2'), url('../static/fonts/lato/latolightitalic.woff') format('woff'), url('../static/fonts/lato/latolightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoregular.woff2') format('woff2'), url('../static/fonts/lato/latoregular.woff') format('woff'), url('../static/fonts/lato/latoregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoitalic.woff2') format('woff2'), url('../static/fonts/lato/latoitalic.woff') format('woff'), url('../static/fonts/lato/latoitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latomedium.woff2') format('woff2'), url('../static/fonts/lato/latomedium.woff') format('woff'), url('../static/fonts/lato/latomedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latomediumitalic.woff2') format('woff2'), url('../static/fonts/lato/latomediumitalic.woff') format('woff'), url('../static/fonts/lato/latomediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latosemibold.woff2') format('woff2'), url('../static/fonts/lato/latosemibold.woff') format('woff'), url('../static/fonts/lato/latosemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latosemibolditalic.woff2') format('woff2'), url('../static/fonts/lato/latosemibolditalic.woff') format('woff'), url('../static/fonts/lato/latosemibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latobold.woff2') format('woff2'), url('../static/fonts/lato/latobold.woff') format('woff'), url('../static/fonts/lato/latobold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latobolditalic.woff2') format('woff2'), url('../static/fonts/lato/latobolditalic.woff') format('woff'), url('../static/fonts/lato/latobolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoheavy.woff2') format('woff2'), url('../static/fonts/lato/latoheavy.woff') format('woff'), url('../static/fonts/lato/latoheavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoheavyitalic.woff2') format('woff2'), url('../static/fonts/lato/latoheavyitalic.woff') format('woff'), url('../static/fonts/lato/latoheavyitalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoblack.woff2') format('woff2'), url('../static/fonts/lato/latoblack.woff') format('woff'), url('../static/fonts/lato/latoblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../static/fonts/lato/latoblackitalic.woff2') format('woff2'), url('../static/fonts/lato/latoblackitalic.woff') format('woff'), url('../static/fonts/lato/latoblackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}





@font-face {    
    font-family: 'latoheavy';
    src: url('../static/fonts/lato/lato-heavy-webfont.eot'), url('../static/fonts/lato/lato-heavy-webfont.woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {    
    font-family: 'latomedium';
    src: url('../static/fonts/lato/lato-medium-webfont.eot'), url('../static/fonts/lato/lato-medium-webfont.woff2');
    font-weight: 500;
    font-style: normal;
}