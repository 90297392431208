
@import "@/../static/SCSS/obit-colors.scss";
.node-hover {
  z-index: 100000;
}
.node.internal_line2-node {
  max-width: inherit;
  .name {
    font-size: 2em;
    font-weight: 300;
  }
  .employee {
    padding-left: 15px;
    padding-bottom: 2px;
    font-weight: 300;
  }
  .add_info_forward {
    margin-left: 20px;
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .b-dropdown-text {
    white-space: pre;
  }
}
