
fieldset.form-group.rules {
  .form-row {
    // display: flex;
    // flex-direction: column;

    .bv-no-focus-ring {
      max-width: 100%;
    }
    .info-block {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      @media (max-width: 640px) {
        flex-flow: wrap-reverse;
        justify-content: end;
        max-width: 274px;
      }
    }
    .groups {
      display: flex;
      flex-direction: column;
      width: auto;
      .groups-block {
        display: flex;
        .input-groups-right {
          @media (max-width: 640px) {
            padding-left: 12px;
          }
        }
      }
      .p-group {
        padding: 11px;
      }
      .p-group-mobile {
        display: none;
        @media (max-width: 640px) {
          display: flex;
          align-items: center;
        }
      }
      .groups-btn {
        font-size: 16px;
        @media (max-width: 640px) {
          padding: 0;
        }
      }
    }
    @media (max-width: 640px) {
      .groups-day {
        margin-right: 12px;
        order: 1;
      }
      .groups-trash {
        position: absolute;
        right: 0;
        bottom: 102px;
      }
    }

    .groups-clock {
      @media (max-width: 640px) {
        order: 1;
      }
    }
    // .groups-day {
    //   margin: 0 40px 0 0;
    // }
    // .groups-disabled {
    //   margin: 0;
    // }
    .groups-timepiker {
      display: flex;
      align-items: center;
    }
    .groups-checkbox {
      display: flex;
      justify-content: space-between;
    }
  }
  .custom-checkbox {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    // width: 50%;
    @media (max-width: 640px) {
      padding: 22px;
      justify-content: end;
      width: 124px;
    }
  }

  .b-form-btn-label-control.form-control > label {
    display: flex;
    align-items: center;
  }
  .custom-select {
    width: 100px;
  }
  .input-group-text {
    background: transparent;
    border: none;

    @media (max-width: 640px) {
      padding-left: 0;
      display: none;
    }
  }
}
.week-input {
  padding-left: 10px;
  .rule-row:not(.delete) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .subrule {
      margin-right: 1em;
    }
  }
}
