
@import "@/../static/SCSS/obit-colors.scss";
.pbxgroup {
  .name {
    font-size: 2em;
    max-width: calc(100% - 20px);
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .output-socket.output {
    position: relative;
    left: calc(100% - 18px);
    &.timeout {
      background-color: $obit-danger !important;
    }
  }
}
