
.def.header {
  padding-left: 15px;
  padding-top: 15px;
  flex-direction: column;
  .back {
    padding-top: 0px;
    padding-left: 0px;
  }
  .def-header {
    padding: 0;
  }
  h1.header {
    padding-bottom: 0px;
    padding-top: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28.6px;
  }
}
