
@import "../../../static/SCSS/obit-colors.scss";
.multiselect {
  &.select2 {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    margin-top: 0px;
  }
  &.multiselect--disabled .multiselect__current,
  &.multiselect--disabled .multiselect__select {
    color: $obit-primary;
    background-color: transparent;
  }
  .multiselect__tags-wrap {
    .multiselect__tag {
      background-color: $obit-primary;
      .multiselect__tag-icon:focus,
      .multiselect__tag-icon:hover {
        background: $obit-danger;
      }
    }
  }
  .multiselect__option {
    &.multiselect__option--highlight {
      background: white;
      outline: none;
      color: $obit-warning;
      &::after {
        background: white;
        color: $obit-warning;
      }
    }
    &.multiselect__option--selected {
      background-color: $obit-primary;
      color: white;
      &::after {
        background: $obit-primary;
        color: white;
      }
      &.multiselect__option--highlight {
        background: $obit-danger;
        outline: none;
        color: white;
        &::after {
          background: $obit-danger;
          color: white;
        }
      }
    }
  }
}
