
.person_cell {
  span {
    width: 100%;
    display: inline-block;
    &.phone {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      white-space: nowrap;
    }
    &.exten {
      font-size: 12px;
      line-height: 14.4px;
      margin-top: 5px;
    }
  }
}
