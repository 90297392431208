
@import "@/../static/SCSS/obit-colors.scss";
.minimap-toggle {
  position: absolute;
  bottom: 1em;
  right: 1em;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
}
.minimap.custom {
  border: 1px solid lighten($obit-primary, 20%);
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);

  padding: 10px;
  .mini-viewport {
    background: rgba(lighten($obit-primary, 35%), 0.32);
    border: 1px solid $obit-primary;
  }
  .mini-node {
    &.default {
      background: $obit-primary;
    }
    &.searched {
      background: $obit-danger;
    }
    &.new {
      background: $obit-export;
    }
  }
}
