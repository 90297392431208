
@import "@/../static/SCSS/obit-colors.scss";
.objects-table {
  thead {
    th {
      border-top: none !important;
      color: $obit-darkgray;
      line-height: 14.4px;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
    }
  }
  tbody {
    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      font-style: normal;
      vertical-align: inherit;
      // vertical-align: bottom;
      // vertical-align: baseline;
      .text-muted {
        font-size: 12px;
        line-height: 14.4px;
      }
    }
  }
}
.paginator-row {
  margin-top: 2em !important;
  margin-left: 25px !important;
  @media (max-width: 480px) {
    margin-left: 0 !important;
  }
}

.pagination .text {
  height: 2.5em;
  line-height: 1.5;
  margin: 0.5em 0.5em;
}

.blur-table::after {
  content: "";
  position: absolute;
  top: 134px;
  right: 0px;
  width: 20px;
  // height: 100%;
  background: linear-gradient(
    90deg,
    #ffffff 7.06%,
    rgba(255, 255, 255, 0) 118.33%
  ); /* transparent keyword is broken in Safari */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
