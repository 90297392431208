
@import "@/../static/SCSS/obit-colors.scss";
.group-list-view {
  max-width: 930px;
  .custom-control {
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  }
  p.roles-list {
    .badge {
      margin-right: 0.5em;
    }
  }
  .group-view {
    display: flex;
    flex-direction: row;
    align-items: stretch !important;
    padding-bottom: 2em;
    &.b-overlay-wrap {
      // border: 1px solid rgba(0, 0, 0, 0.125);
      align-items: baseline;
      .list-group {
        width: 100%;
      }
    }
    .group-items {
      max-width: 350px;
      width: 100%;
      min-height: 20em;
      padding-left: 18px;
      padding-top: 1em;
    }
    .icon-block {
      padding: 0 7px 0 0;
    }
    .group-info {
      border-left: 1px solid $obit-lightgray;
      height: auto;
      padding-left: 2em;
      padding-top: 1em;
      align-self: stretch;
    }
  }
}
