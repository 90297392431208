
@import "@/../static/SCSS/obit-colors.scss";

.user-info {
  display: flex;
  flex-direction: column;
}

.topbar {
  min-height: 90px;
  max-width: 100vw;
  flex-wrap: nowrap !important;

  &.collapsed {
    .user-dropdown {
      padding-right: inherit !important;
    }
  }

  .obit-logo {
    background-image: url("../../assets/obit24_logo_hor.svg");
    background-repeat: no-repeat;
		background-size: contain;
    width: 5.5rem;
    height: 32px;
    line-height: 4rem;
    @media screen and (min-width: 900px) {
      margin-right: 7.5rem;
    }

		&._service {
			background-image: url("../../assets/it-service_logo.png");
		}
  }

  .top-actions {
    .no-hover {
      background-color: inherit;

      a {
        background-color: inherit;
        cursor: default;
      }
    }

    button,
    a {
      padding-left: 15px;
      padding-right: 15px;
    }

    .user-dropdown {
      padding-right: 30px;

      button {
        &:hover {
          color: $obit-warning;
        }
      }

      ul.dropdown-menu {
        padding: 0;
        margin-top: 20px;
        position: absolute;

        .dropdown-divider {
          border: 0;
        }

        button.logout {
          font-weight: 700;
          font-size: 14px;
          line-height: 16.8px;
          padding: 12px 25px;
        }

        li > * {
          padding: 5px 20px;
          line-height: 24px;
        }

        li:last-child > * {
          padding-bottom: 20px;
        }

        li.info {
          div {
            padding: 20px;
          }
        }
      }
    }
  }

  .varitant-text {
    line-height: 14.4px;
    font-size: 12px;
    font-weight: 400;
    padding-top: 0.5em;
  }
}
