
.spec-list {
  padding: 15px;
  .title {
    padding: 15px 0px;
    .head {
      $size: 30px;
      font-size: $size;
      line-height: $size + 2.2px;
      text-transform: initial;
    }
  }
}
