
.page-404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  .middle {
    margin: auto;
    display: flex;
    flex-direction: column;
    h1,
    a {
      margin-top: 1em;
      font-size: 2em;
    }
  }
}
