
.dropdown.field_actions {
  float: right;
  button {
    padding-right: 2px;
    padding-left: 2px;
  }
  ul {
    padding: 20px 0px;
    margin-top: 10px;
    margin-right: 7px;
    a {
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      float: right;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.right {
  float: right;
}
