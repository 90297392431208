
.btn-outline-primary:hover {
  border: none;
}

.responsive-modal div.modal-dialog {
  max-width: fit-content;
}
.responsive-modal legend {
  min-width: 160px;
}
