[class^=iradio_] ~ label::before, [class^=iradio_] ~ label::after, [class^=icheckbox_] ~ label::before, [class^=icheckbox_] ~ label::after {
    content: none;
}
[class^=iradio_] ~ label, [class^=icheckbox_] ~ label {
    margin-left: 0.5rem;
}
nav .nav-second-level li a {
    padding-left: 35px;
}
nav .nav-third-level li a, .fixed-sidebar.mini-navbar  nav .nav-second-level li .nav-third-level li a {
    padding-left: 50px;
}
button > svg[alt=icon] {
    transform: translate(-18%, 0%);
}
.nav-second-level li:first-child {
    padding-top: 10px;
}