
.btn-outline-primary:hover .hover-danger {
  &:hover {
    color: red !important;
    border-color: red !important;
  }
}
.lkEdit-btn {
  width: 1.9em !important;
  height: 1.2em !important;
}
.objects-media,
.objects-play_file {
  td.field_actions,
  th.field_actions {
    width: 40px;
  }
  .media-btn-settings {
    padding: 0 40px;
  }
}
