
@import "@/../static/SCSS/obit-colors.scss";
.node.pbx_group-node {
  max-width: 250px;
  .name {
    $size: 2em;
    font-size: $size;
    line-height: 1.2em;
    max-width: calc(100% - 20px);
    font-weight: 300;
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    background: #54bae7;
    font-weight: 300;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .out-line {
    height: 3em;
    .name {
      display: block;
      padding-left: 10px;
    }
    .output-socket.output {
      position: relative;
      left: calc(100% - 30px);
      top: -2.5em;
      &.timeout {
        background-color: $obit-danger !important;
      }
    }
    .out-line-span {
      font-size: 24px;
      border: 2px solid #54bae7;
      border-radius: 50px;
      max-width: 146px;
      width: 100%;
      margin-left: 178px;
      padding: 0 10px;
      padding-left: 20px;
    }
  }
}
