
@import "@/../static/SCSS/obit-colors.scss";
.node {
  border: none;
  box-shadow: 0px 28px 64px -27px #c1c1c1;
  font-weight: 300;
  max-width: 250px;
  z-index: 80 !important;
  .additional-info {
    z-index: 10000;
  }
  &.node-hover {
    z-index: 8000 !important;
  }
  &.node-new {
    border: 2px solid $obit-export;
    box-shadow: 0px 28px 64px -27px $obit-export;
  }
  &.node-search {
    border: 2px solid $obit-danger;
    box-shadow: 0px 28px 64px -27px $obit-danger;
  }
  .edit & {
    :not([title="call"]):hover {
      cursor: move;
    }
    &:hover .btn {
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  .name {
    font-size: 2em;
    line-height: 1.2em;
    padding-left: 0.5em;
    max-width: calc(100% - 20px);
    font-weight: 300;
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    line-height: 37px;
    color: #fff;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
      float: left;
    }
    .object-modal {
      width: fit-content;
      display: inline;
      float: right;
      background-color: transparent;
      & > button.btn {
        color: white;
      }
    }
  }
  .output-line {
    margin-right: -4px;
    .out {
      border: 2px solid var(--primary);
      border-radius: 50px;
      max-width: 98%;
      width: fit-content;
      height: 32px;
      margin-bottom: 5px;
      float: right;
      .name {
        display: block;
        font-size: 24px;
        line-height: 1.2em;
        padding-left: 0.8em;
        padding-right: 30px;
        max-width: fit-content;
        white-space: nowrap;
      }
      .output-socket.output {
        position: relative;
        left: calc(100% - 31px);
        top: -32px;
        &.timeout {
          background-color: $obit-danger !important;
        }
      }
    }
  }
  .output-socket.output {
    position: relative;
    left: calc(100% - 18px);
    &.timeout {
      background-color: $obit-danger !important;
    }
  }
}
