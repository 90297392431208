
.customer-top-form {
  .select-customer,
  .select-contract {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
    height: 1.1em;
    options: {
      color: var(--dark);
    }
    &:focus {
      box-shadow: none;
    }
    &[disabled],
    &:disabled {
      background-color: transparent;
    }
  }
  .select-customer {
    font-weight: 600;
    margin-bottom: 0.25em;
    font-size: 14px;
  }
  .select-contract {
    font-weight: 400;
    min-width: 100%;
    font-size: 12px;
    line-height: 14.1px;
  }
}
.no-display {
  display: none;
}
