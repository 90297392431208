
.node {
  &.play_file-node {
    max-width: inherit;
    .node-edit {
      display: inline;
      border: none;
      .title-icon {
        color: #fff;
      }
    }
    .subname {
      font-weight: 300;
      padding: 20px;
    }
    h2.name {
      max-width: 350px;
      line-height: 1em;
      overflow-wrap: break-word;
    }
  }
}
