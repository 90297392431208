
#tarif_view {
  .row_padding {
    padding-left: 30px;
  }
  .subform {
    margin: 20px 0;

    fieldset {
      padding-left: 5px;
      font-size: 16px;
    }
  }
  .subform:not(:last-child) {
    border-bottom: 1px solid var(--light);
  }

  .form-row {
    align-items: center;
    @media (max-width: 576px) {
      padding: 5px 0;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }
  }
  .form-text {
    min-width: 50%;
  }
  .form-block {
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
}
