
.archive {
  .overlay {
    max-width: 760px;
    min-height: 300px;
    margin-bottom: 1em;
  }
  & .form-inline {
    .form-group {
      margin-bottom: 0;
    }
    .btn {
      margin-right: 0;
    }
    @media screen and (max-width: 800px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    & > .col {
      flex-grow: 0;

      &:not(:first-child, :last-child) {
        margin-right: 5px !important;
        padding: 0px 2px;
      }
    }
  }
  .archive-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    &.blur {
      backdrop-filter: blur(2px);
      z-index: 90;
    }
  }
  .form-inline {
    padding-top: 25px;
    padding-bottom: 7px;
    max-width: 930px;
    justify-content: space-between;
  }
  .btn-outline-export {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05em;
  }
  .table {
    min-width: 660px;
    overflow: scroll;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    color: white;
  }
  .btn-filter {
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-right: 30px;
    font-weight: bold;
    font-size: 13px;
  }
  .search-input {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .archive {
      .form-inline {
        padding-top: 20px;
        padding-bottom: 0px;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1130px) {
    .search-input {
      width: 193px !important;
    }
  }

  @media (max-width: 992px) {
    .justify-content-end {
      visibility: hidden;
    }
  }
  @media (min-width: 576px) {
    .col-sm-4 {
      max-width: 47.33333%;
    }
  }
  @media (min-width: 992px) {
    .col-lg-3 {
      max-width: 33%;
    }
  }
}
