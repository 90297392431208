
.objects-list {
  background-color: white;
  .objects-dsip,
  .objects-internet {
    td.field_name a.number-cell {
      white-space: break-spaces;
    }
    td.field_date_begin_dateonly {
      white-space: nowrap;
    }
  }
  .objects-calculation {
    td.field_bill,
    td.field_payment {
      text-align: right;
    }
  }
  .objects-bill {
    td.field_amount {
      text-align: right;
    }
  }
}
