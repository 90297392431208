
.search_filter {
  .form-row.default_filter {
    body.vertical & {
      margin-left: 0;
      margin-right: 0;
      button.btn.add-item {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }
    }
    .input-group {
      width: 286px !important;
      body.vertical & {
        width: 50% !important;
      }
      .focus {
        border-color: var(--primary) !important;
      }
      .clear-input {
        max-height: 38px;
      }
    }
    &:first-child {
      margin-left: 1em;
      flex: 2;
    }
    .export {
      margin-right: 1em;
      vertical-align: middle;
      font-weight: 500;
      body.vertical & {
        display: none;
      }
    }
  }
}
