
#page {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 90px 70px 1fr;
  grid-template-areas:
    "top"
    "side"
    "page";
  background-color: var(--light);
  @media screen and (min-width: 801px) {
    grid-template-rows: 90px 1fr;
    grid-template-columns: 240px 1fr;
    grid-template-areas:
      "top top"
      "side page";
  }

  #page-wrapper {
    width: calc(100vw - 240px);
    grid-area: page;
    height: calc(100vh - 90px);
    overflow: auto;
    .page {
      min-height: calc(100vh - 90px - 90px);
      padding: 30px;
      padding-bottom: 0;
    }
    @media screen and (max-width: 800px) {
      width: 100vw;
      height: 100%;
      .page {
        min-height: calc(100vh - 90px - 90px - 70px);
        padding: 12px 0;
        .row {
          margin: 0;
        }
        .col {
          padding: 0;
        }
      }
    }
  }
  &.manager_transfered {
    #page-wrapper {
      @media screen and (max-width: 800px) {
        .page {
          min-height: calc(100vh - 90px - 90px - 70px - 162px) !important;
        }
      }
    }
  }
  .menu {
    height: 100%;
    grid-area: side;
  }
  .topbar {
    grid-column: span 2;
    grid-area: top;
    height: fit-content !important;
    padding-left: 30px;
  }
  nav.footer {
    width: 100%;
    height: 90px !important;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    @media (max-width: 801px) {
      height: auto !important;
    }
    @media (max-width: 480px) {
      padding-right: 0;
    }
  }

  @media (max-width: 640px) {
    .footer {
      height: auto !important;
    }
  }
  // @media (max-width: 480px) {
  //   .footer {
  //     height: 150px !important;
  //   }
  // }
}
