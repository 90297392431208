
$borderParam: 1px solid #123;
@import "@/../static/SCSS/obit-colors.scss";

.tariff-settings {
  .search {
    max-width: 930px;
    margin-top: 25px;
    margin-bottom: 0.5em;

    .search-input {
      border-radius: 6px;
      margin-right: 4px;

      &.focus-input {
        border-radius: 6px 0 0 6px;
        margin-right: 0;
      }
    }

    .btn {
      border-radius: 0 0.25rem 0.25rem 0;
      padding-bottom: 0;
      padding-top: 0;
      margin-right: 4px;

      &.dropdown-toggle {
        border-radius: 6px;
      }
    }
  }

  .b-table-sticky-header {
    max-height: calc(100vh - 530px);

    .vertical & {
      max-height: calc(100vh - 150px);
    }
  }

  .objects-table {
    table {

      td.table-grey,
      th.table-grey {
        background-color: lighten(#e0e722, 38%);
      }

      td.table-crm,
      th.table-crm {
        background-color: lighten(green, 72%);
      }
    }
  }

  .group_search {
    max-width: 20em;
  }

  .focus {
    border-color: var(--primary) !important;
  }

  .table-employee-list {
    display: grid;
    grid-template-columns: 5fr 2fr;
    border: $borderParam;

    .table__row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      &:first-child {
        background-color: aqua;
      }

      &.active {
        background-color: rgb(181, 159, 204);
      }
    }

    .table__col {
      padding: 20px 15px;
      border-bottom: $borderParam;

      &:not(:first-child) {
        border-left: $borderParam;
      }
    }

    .table-vats {
      background: rgb(255, 255, 196);
      padding: 0 20px 20px;
      border-left: $borderParam;
    }

    .table-vats__col {
      padding: 20px 15px;
      border-bottom: $borderParam;
    }

    .table__name {
      height: 58px;
    }

    .table__id {
      text-align: center;
      height: 58px;
    }

    .table__buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}
