
$additional_field_length: 150px;
.tarrif-calls {
  form.form-inline.filter {
    justify-content: start;
    .col.d-flex {
      flex-grow: 1;
      width: fit-content;
      max-width: fit-content;
      min-width: fit-content;
      &.additional-filter {
        & > fieldset {
          margin: 0;
          margin-right: 0.5em;
          & > [role="group"] {
            display: flex;
            flex-wrap: nowrap;
            & > * {
              margin-right: 1em;
            }
            .legend {
              min-width: 2em;
              padding-right: 0.5em;
              text-align: right;
            }
          }
        }
      }
    }
  }
  table {
    td.field_from,
    td.field_to {
      div {
        white-space: nowrap;
      }
    }
    td.field_price {
      div {
        text-align: right;
      }
    }
  }
}
