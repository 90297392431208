
.call_status {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 9px;
}
.call-icon {
  font-size: 1.7em;
}
td.field_icon,
th.field_icon {
  width: 4em;
}
