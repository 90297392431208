
@import "../../../static/SCSS/obit-colors.scss";
.entity_status {
  & > * {
    display: inline-block;
    text-align: left;
    margin-right: 0.5em;
  }
  .status {
    .topbar.collapsed & {
      display: none;
    }
    font-weight: 600;
    vertical-align: top;
    font-size: 14px;
  }
  .detail {
    .summ {
      font-weight: 600;
      display: inline-block;
      margin-bottom: 0.25em;
      font-size: 14px;
    }
    .comment {
      display: inline-block;
      font-size: 12px;
      line-height: 14.4px;
      color: $obit-darkgray;
    }
  }
}
