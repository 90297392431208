
@import "../../../static/SCSS/obit-colors.scss";
.menu {
  max-width: 100vw;
  overflow: auto;
  @media screen and (max-width: 800px) {
    height: 70px;
    .simplebar-track.simplebar-horizontal {
      display: none;
    }
  }
}
.side-menu.nav {
  background-color: $obit-gray;
  padding-top: 3rem;
  min-height: calc(100vh - 90px);
  flex-direction: column;
  @media screen and (max-width: 800px) {
    padding-top: 0;
    width: 100%;
    flex-direction: row;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    padding-top: 15px;
    &.nav {
      flex-wrap: initial !important;
      width: fit-content;
      min-width: 100%;
      // overflow-x: auto;
    }
  }
  .invisible_el {
    width: 0;
  }
  .nav-scroll {
    position: fixed;
    $color-without-alpha: $obit-gray;
    $color-with-alpha: rgba($color-without-alpha, 0.2);
    background: linear-gradient(
      to right,
      $color-without-alpha,
      $color-with-alpha
    );
    & > a {
      color: var(--dark);
      :hover {
        color: #fd6300;
      }
    }
    &.right {
      right: 0;
      background: linear-gradient(
        to right,
        $color-with-alpha,
        $color-without-alpha
      );
    }
  }
}
