
.obit-spinner {
  position: relative;
  left: calc(50% - 120px);
  width: 20em;
  height: 20em;
  .spinner {
    width: 20em;
    height: 20em;
    &.spinner-1 {
      width: 18em;
      height: 18em;
      position: absolute;
      top: 1em;
      left: 1em;
    }
  }
  .obit-logo {
    background-image: url("../../assets/obit_spinner.gif");
    //background-position: 50% 50%;
    position: absolute;
    top: 2.5em;
    left: 2.5em;
    width: 15em;
    height: 15em;
    background-repeat: no-repeat;
  }
}
.overlay-spinner {
  .obit-logo {
    background-image: url("../../assets/obit_spinner.gif");
    background-position: 50% 50%;
    width: 15em;
    height: 5em;
    background-repeat: no-repeat;
  }
}
