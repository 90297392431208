
@import "@/../static/SCSS/obit-colors.scss";
.group-item {
  display: flex;
  flex-direction: column !important;
  border: none;
  padding: 0;
  .margin-right-auto {
    margin-left: auto;
  }
  .item-block {
    padding: 2px 5px;
    padding-left: calc(var(--left-padding) + 1em);
    display: flex !important;
    align-items: center;
    &.active {
      background-color: $obit-primary;
      border-radius: 5px;
      color: white;
      & > * {
        color: white;
      }
      &.text-primary {
        color: white;
      }
      .btn {
        color: white;
        &[disabled] {
          color: lighten($obit-primary, 20%);
        }
      }
    }
    .text {
      margin-right: 1em;
    }

    .subitem {
      padding: 2px 5px;
    }
    .btn {
      padding: 2px 10px;
      border: none;
    }
  }
  .group-item-1 {
    padding: 5px;
  }
  &.list-group-item {
    // padding: 17px 0;
    display: flex;
    flex-direction: row;
  }
}

.group-employees {
  width: 50%;
}
