
.calltime-cell {
  & > * {
    width: 100%;
    display: block;
  }
  .time {
    margin-top: 5px;
  }
}
